import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

  <Layout title="About us" pgTitle="Overview">
    <section className="section-aboutus">
      Quantic Cloud Technologies Pvt. Ltd. (CIN - U72200MP2019PTC049234) is a Software Development/Technologies Company, specialized in design, development, and support, custom tailor-made software, POS, and FinTech, and cloud solutions.
      <br/>
      <br/>
      
      We will help our client to build large digital strategies that address the full guest experience, from ordering on a mobile device to purchasing on-site right up through food delivery.
      <br/>
      <br/>
      
      As part of our range of professional IT services, we help governments, small to medium size companies select, implement and manage the right technologies
      to drive their business goals and objectives.
      <br/>
      <br/>

      Quantic, has been profitable every year since 2018. Our team is well versed in strategy, technology, lean development, Six Sigma, ITIL, Project & Program
      Management, Portfolio Management, & IT Security - PCI-DSS, and HIPPA.
      <br/>
      <br/>

      We are a heavy Engineering focused company with capabilities that include pre-sales and post-sales deployment. Quantic
      associates are seasoned executives from Fortune 500 companies like UPS, Humana, GE, Southern Companies, and Western Southern Financial Group.
      <br/>
      <br/>

      <h4>Our Mission</h4>
      Our mission is to create solutions for profitability and success.We offer integrated and synergistic technology and marketing solutions for global
      businesses. We are dedicated to developing fully customized, accountable programs to address each client’s unique situation. We are further committed to
      analyzing the analytics data we collect and translating it into kernels of actionable business intelligence that can directly impact each client’s growth
      and success.
      <br/>
      <br/>

      <h4>Our Vision</h4>
      We want to be recognized as a leading provider of technology solutions, product development solutions, engineering and data solutions. We want to grow the
      competitive advantage our associates give us in the marketplace, we are continually be on the cutting edge of people systems and processes. We’re creating
      our future together. With a visionary mindset and spirit we want our associates to see bigger ideas and make positive changes in their own lives.

      <br/>
      <br/>
      <br/>
      {/*

      <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 text-center">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Established</h5>
              Started in 2006, we are based in Louisville, Kentucky.
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 text-center">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Certification</h5>
              Certified Woman Owned Minority Business (MWBE).
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 text-center">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Clients</h5>
              From Small Businesses to Fortune 100 Clients.
            </div>
          </div>
        </div>
      </div>
*/}


    </section>
  </Layout>
);


export default Page;
